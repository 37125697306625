import { createContext, useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const RequestSongContext = createContext();
// const backendURL = "http://127.0.0.1:8000/api/";
const backendURL = "https://our-song-request-app-9e5461b710df.herokuapp.com/api/";

function RequestSongProvider({ children }) {
  const [orderId, setOrderId] = useState();
  const [loading, setLoading] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  // email sending - later move to new context
  const [emailData, setEmailData] = useState({
    message: "",
    business_name: "",
    email: "",
  });

  function handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
    setEmailData({ ...emailData, [name]: value });
  }

  async function sendRequestSongOrder(data, file) {
    const formData = new FormData();
    const postData = JSON.stringify({
      name: data.name,
      songAtmoshpere: data.songAtmoshpere,
      hobbies: data.hobbies,
      personality: data.personality,
      specificDetails: data.specificDetails,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      acrylicPhotoAmount: data.acrylicPhotoAmount,
      postcode: data.postcode,
      houseNumber: data.houseNumber,
      songId: data.songId,
      funeralDirector: data.funeralDirector,
    });

    formData.append("post_data", postData);
    formData.append("file", file);
    console.log(formData);
    try {
      setLoading(true);
      const response = await fetch(`${backendURL}request-order/`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error();
      }

      setOrderId(data.id);
      setErrorMessage(null);
      navigate("payment");
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error);
    } finally {
      setLoading(null);
    }
  }

  async function requestPayPalPayment() {
    try {
      setLoading(true);

      const response = await fetch(`${backendURL}paypal/create/`, {
        method: "POST",
        body: JSON.stringify({ orderId }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data["errorMsg"]);
      }

      setOrderId(null);
      setErrorMessage(null);
      setLoading(false);

      const payPalSessionURL = data["orderLink"];
      window.location.href = payPalSessionURL;
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error);
    } finally {
      setLoading(null);
    }
  }

  async function requestStripePayment() {
    try {
      setLoading(true);

      const response = await fetch(`${backendURL}create-checkout-session/`, {
        method: "POST",
        body: JSON.stringify({ orderId }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data["errorMsg"]);
      }

      setOrderId(null);
      setErrorMessage(null);
      setLoading(false);

      const payPalSessionURL = data["sessionUrl"];
      window.location.href = payPalSessionURL;
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error);
    } finally {
      setLoading(null);
    }
  }

  async function contactUs() {
    try {
      setLoading(true);

      const response = await fetch(`${backendURL}contact-us/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...emailData }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data["errorMsg"]);
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error);
    } finally {
      setLoading(null);
    }
  }

  // memoria
  async function memoriaSubmit(formData, answers) {
    try {
      setLoading(true);

      // Convert file to base64 if a file is selected
      let fileBase64 = "";
      if (formData.file) {
        fileBase64 = await convertFileToBase64(formData.file);
      }

      // Merge formData and answers (note: the key lovedOneName maps to the serializer’s lovedOneName)
      const payload = {
        name: formData.name,
        lovedOneName: formData.lovedOneName,
        email: formData.email,
        phone: formData.phone,
        file: fileBase64,
        memory: answers.memory,
        personality: answers.personality,
        appreciation: answers.appreciation,
        lesson: answers.lesson,
      };

      const response = await fetch(`${backendURL}memoria/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data["errorMsg"]);
      }
      toast.success("Your submission was successful!");
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error);
    } finally {
      setLoading(null);
    }
  }

  // Helper function to convert file to Base64 string
  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const contextData = {
    sendRequestSongOrder,
    requestPayPalPayment,
    requestStripePayment,
    handleChange,
    contactUs,
    memoriaSubmit,
    loading,
    errorMessage,
    orderId,
    backendURL,
  };

  return <RequestSongContext.Provider value={contextData}>{children}</RequestSongContext.Provider>;
}

function useRequestSong() {
  const context = useContext(RequestSongContext);
  if (context === undefined)
    throw new Error("RequestSongContext was used outside RequestSongProvider");
  return context;
}

export { RequestSongProvider, useRequestSong };
