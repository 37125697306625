import React, { useEffect, useState } from "react";
import memoriaLogo from "../images/memoria-logo.png";
import uploadImg from "../images/upload-image.png";
import "./MemoriaTributeSongs.css";
import { useRequestSong } from "../context/RequestOrderContext";
import SpinnerFullPage from "../components/ReusableComponents/FullPageLoader/SpinnerFullPage";
import toast from "react-hot-toast";

const steps = [
  {
    title: "What are your favourite memories of your loved one?",
    subtitle: "Select one core memory that will always resonate with you.",
    name: "memory",
    options: ["Watching films together", "Our family holidays", "Our long talks", "Our long walks"],
  },
  {
    title: "What was their personality like?",
    subtitle: "Select a personality trait that best reflect who they were and how they stood out.",
    name: "personality",
    options: [
      "They had a great sense of humour",
      "Their empathy and care for others",
      "Their selflessness and putting others first",
    ],
  },
  {
    title: "What have you appreciated the most about them?",
    subtitle: "Select a quality you valued most about them and what made them special to you.",
    name: "appreciation",
    options: ["Always made time for us", "The sacrifices they made for us", "Made us proud"],
  },
  {
    title: "What did they teach you?",
    subtitle: "Select a lesson or values they passed down that have stayed with you.",
    name: "lesson",
    options: [
      "What it means to be a good person",
      "The importance of caring for others",
      "How to work hard",
    ],
  },
];

const MemoriaTributeSongs = () => {
  const { memoriaSubmit, loading, errorMessage } = useRequestSong();
  const [answers, setAnswers] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    lovedOneName: "",
    email: "",
    phone: "",
    file: null,
  });

  const handleOptionSelect = (stepName, option) => {
    setAnswers((prev) => ({
      ...prev,
      [stepName]: option,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ answers, formData });
    // alert("Form submitted!");
    memoriaSubmit(formData, answers);
  };

  useEffect(() => {
    if (!errorMessage) return;
    toast.error(errorMessage);
  }, [errorMessage]);

  if (loading) return <SpinnerFullPage />;
  return (
    <>
      <div className="memoria-navbar">
        <img src={memoriaLogo} alt="memoria logo" className="memoria-logo" />
      </div>
      <form className="memoria-wrapper" onSubmit={handleSubmit}>
        <h2
          style={{
            fontSize: "54px",
            fontWeight: 700,
            color: "#202020",
            textAlign: "center",
            marginTop: "40px",
          }}
        >
          Memoria Tribute Songs
        </h2>

        {steps.map((step, index) => (
          <div className="step" key={index}>
            <div className="progress-bar">
              <div
                className="progress-fill"
                style={{
                  width: `${((index + 1) / (steps.length + 1)) * 100}%`,
                }}
              ></div>
            </div>
            <h2>{step.title}</h2>
            <p className="subtitle">{step.subtitle}</p>
            {step.options.map((opt, idx) => (
              <button
                type="button"
                key={idx}
                className={`option ${answers[step.name] === opt ? "selected" : ""}`}
                onClick={() => handleOptionSelect(step.name, opt)}
              >
                {opt}
              </button>
            ))}
          </div>
        ))}

        <div className="step">
          <div className="progress-bar">
            <div className="progress-fill" style={{ width: "100%" }}></div>
          </div>
          <h2>Add your information</h2>
          <p className="subtitle">Please fill out the brief form below.</p>
          <div className="form-ctrl">
            <label>Name</label>
            <input type="text" name="name" onChange={handleInputChange} required />
          </div>
          <div className="form-ctrl">
            <label>Loved one's name</label>
            <input type="text" name="lovedOneName" onChange={handleInputChange} required />
          </div>
          <div className="form-ctrl">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-ctrl">
            <label>Phone</label>
            <input type="text" name="phone" placeholder="Phone" onChange={handleInputChange} />
          </div>
          <div className="form-ctrl">
            <label>Upload Photo</label>
            <label className="file-upload">
              <input type="file" name="file" onChange={handleInputChange} />
              <div className="upload-box">
                <div className="upload-img-wrapper">
                  <img src={uploadImg} alt="Upload Arrow" />
                </div>
                <p style={{ fontSize: "18px" }}>
                  <strong style={{ color: "#2068C7" }}>Click to upload</strong> or drag and drop
                </p>
                <small style={{ fontSize: "16px", color: "#20202080" }}>
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </small>
              </div>
            </label>
            <label className="checkbox" style={{ fontSize: "18px", color: "#202020CC" }}>
              <input type="checkbox" required /> By checking this box, you agree to share your email
              address and phone number with Memoria's marketing and sales teams.
            </label>
          </div>
          <div
            style={{
              marginBottom: "80px",
            }}
          >
            <button type="submit" className="submit-btn">
              Submit
            </button>
          </div>
        </div>
      </form>
      <div className="memoria-footer"></div>
    </>
  );
};

export default MemoriaTributeSongs;
